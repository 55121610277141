<template>
    <div class="form_box ly_form_box ly_userForm">
        <app-text
            v-model="title"
            label="タイトル"
            class="uq_wd_15em"
        />
        <div class="common_text_column input_container">
            <app-label
                name="show_type"
                label="表示種別"
            />
            <app-select 
                v-model="show_type"
                label="選択してください"
                :vmodel-label="master.informationShowType[show_type]"
                class="uq_passId"
            >
                <app-select-option
                    :value="null"
                    v-model="show_type"
                >
                    選択してください
                </app-select-option>
                <app-select-option
                    v-for="(item, index) in master.informationShowType"
                    :key="'informationShowType' + index"
                    :value="index"
                    v-model="show_type"
                >
                    {{item}}
                </app-select-option>
            </app-select>
        </div>
        <div class="common_text_column input_container">
            <app-label
                name="text"
                label="本文"
            />
            <textarea
                v-model="text"
                ref="jest-information-text"
                class="uq_wd23em_ht2em"
            />
        </div>
    </div>
</template>

<script>
/**
 * ユーザ情報の入力フォーム
 */
import { mapGetters, mapActions } from 'vuex'
import { mapComputedProperties } from '@/util'
import master from '@/mixins/master'

export default {
    name:'InformationForm',
    mixins:[master],
    computed:{
        ...mapGetters({
            data:'informations/information',
        }),
        ...mapComputedProperties([
            'title',
            'text',
            'show_type',
            'show_users'
        ]),
    },
    methods:{
        ...mapActions({
            update:'informations/updateInformation'
        })
    }
}
</script>

<style scoped>
.ly_form_box >>> div.common_text_column, .form_box >>> div.formrow {
    grid-template-columns: 6em minmax(300px,1fr);
    -ms-grid-columns: 6em minmax(300px,1fr);
}
.form_box >>> .uq_wd_15em input[type="text"]{
    min-width: 16.5em;
}
.uq_wd23em_ht2em{
    min-width: 26em;
    min-height: 2em;
}
</style>