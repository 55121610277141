<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト'"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">お知らせ{{typeString}}</h2>
            </div>
            <error-list :class="{notice:!isDelete}" />
            <information-confirm />
            <div class="ly_buttonBox">
                <app-button
                    name="戻る"
                    class="cancel"
                    @click="back"
                    ref="jest-informations-back"
                />
                <app-button
                    v-if="isDelete"
                    class="delete"
                    name="削除"
                    @click="deleteInformation"
                    ref="jest-informations-delete"
                />
                <app-button
                    v-if="!isDelete"
                    name="変更"
                    @click="edit"
                    ref="jest-informations-edit"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { move } from '@/util'
import Progress from '@/mixins/progress.js'
import ErrorList from '@/components/molecules/ErrorList'
import InformationConfirm from '@/components/organisms/InformationConfirm'

export default {
    name: 'AdminInformationsDetail',
    mixins:[Progress],
    components:{
        ErrorList,
        InformationConfirm,
    },
    computed:{
        ...mapGetters({
            information:'informations/information',
        }),
        isDelete(){
            return this.$route.meta.type === 'delete'
        },
        typeString(){
            return this.isDelete ? '削除' : '詳細'
        },
    },
    methods:{
        ...mapActions({
            delete:'informations/delete',
            clearError:'error/clear',
            updateError:'error/update'
        }),
        moveTo(path){
            move(this.$router, path)
        },
        deleteInformation(){
            const path = this.isDelete ? '/admin/information' : '/admin/information/' + this.information.id + '/delete'
            this.clearError()
            return this.delete()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.moveTo(path)
                    } else {
                        this.updateError(response)
                    }
                })
        },
        back(){
            this.clearError()
            this.moveTo('/admin/information')
        },
        edit(){
            this.clearError()
            this.moveTo('/admin/information/' + this.information.id + '/edit')
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 600px;
    max-width: 600px;
}
.form_box {
    width: 100%;
}
.formrow{
    width: 100%;
}
</style>