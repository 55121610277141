<template>
    <div class="form_box ly_form_box ly_userForm">
        <div class="formrow">
            <app-label
                name="title"
                label="タイトル"
            />
            <p>{{information.title}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="date"
                label="公開日"
            />
            <p>{{date(information.date)}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="show_type"
                label="表示種別"
            />
            <p>{{master.informationShowType[information.show_type]}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="text"
                label="本文"
            />
            <p>{{information.text}}</p>
        </div>
    </div>
</template>

<script>
/**
 * ユーザ情報の入力フォーム
 */
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import master from '@/mixins/master'

export default {
    name:'InformationConfirm',
    mixins:[master],
    computed:{
        ...mapGetters({
            information:'informations/information',
        }),
        date(){
            return function(dateString){
                return dateString ? moment(dateString).format('YYYY年MM月DD日') : '-'
            }
        }
    },
    methods:{
        ...mapActions({
            update:'informations/updateInformation'
        })
    }
}
</script>

<style scoped>
.form_box >>> div.formrow {
    grid-template-columns: 6em minmax(300px,1fr);
    -ms-grid-columns: 6em minmax(300px,1fr);
}
</style>