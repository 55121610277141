<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト'"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">お知らせ一覧</h2>
                <app-button
                    name="新規作成"
                    @click="create"
                    ref="jest-informations-create"
                    class="inline"
                />
            </div>
            <error-list class="notice" />
            <p v-if="total != 0">{{total}}件中{{start}}～{{end}}件表示</p>
            <table
                v-if="informations.length !== 0"
                key="resultTable"
                ref="jest-result-table"
                class="lists_table"
            >
                <tr>
                    <th>件名</th>
                    <th>表示種別</th>
                    <th>公開日</th>
                    <th></th>
                </tr>
                <tr
                    v-for="item in informations"
                    :key="'informations' + item.id"
                    :ref="'jest-informations' + item.id"
                >
                    <td>{{item.title}}</td>
                    <td>{{master.informationShowType[item.show_type]}}</td>
                    <td>{{date(item.date)}}</td>
                    <td>
                        <app-button
                            name="詳細"
                            @click="moveTo(item, '/admin/information/' + item.id)"
                            :ref="'jest-informations-detail' + item.id"
                            class="inline wd_80px"
                        />
                        <app-button
                            name="削除"
                            class="delete inline wd_80px"
                            @click="moveTo(item, '/admin/information/' + item.id + '/delete')"
                            :ref="'jest-informations-delete' + item.id"
                        />
                    </td>
                </tr>
            </table>
            <list-pager
                v-if="informations.length !== 0"
                :page="page"
                :page-count="pageCount"
                @page-change="pageChange"
            />
            <p
                v-if="informations.length === 0"
                key="resultParagraph"
                ref="jest-result-paragraph"
            >
                お知らせがありません
            </p>
        </div>
    </default-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { move, pageContentCount } from '@/util'
import Progress from '@/mixins/progress.js'
import master from '@/mixins/master.js'
import ErrorList from '@/components/molecules/ErrorList'
import ListPager from '@/components/molecules/ListPager'

export default {
    name: 'AdminInformationsList',
    mixins:[Progress, master],
    components:{
        ErrorList,
        ListPager,
    },
    computed:{
        ...mapGetters({
            informations:'informations/informations',
            total:'informations/total',
            page:'informations/page'
        }),
        /**
         * リストの件数の合計から算出されるリストのページ数を返す
         */
        pageCount(){
            return Math.ceil(this.total / pageContentCount)
        },
        /**
         * 日付文字列を「YYYY年MM月DD日」の形式に成形
         */
        date(){
            return function (dateString) {
                return dateString ? moment(dateString).format('YYYY年MM月DD日') : '-'
            }
        },
        start(){
            return 1 + (this.page - 1) * pageContentCount
        },
        end(){
            const count = this.page * pageContentCount
            return count < this.total ? count : this.total
        },
    },
    async mounted(){
        await this.update({page:1})
        this.getInformationsList()
    },
    methods:{
        ...mapActions({
            getInformations:'informations/getInformations',
            update:'informations/update',
            clearError:'error/clear',
        }),
        /**
         * リンクリストのページの切り替え
         */
        async pageChange(page){
            const loader = this.$loading.show()
            await this.update({page})
            await this.getInformationsList()
            loader.hide()
        },
        async getInformationsList(){
            const loader = this.$loading.show()
            this.startProgress()
            await this.getInformations({
                show_users:null,
                show_type:null,
            })
            this.finishProgress()
            loader.hide()
        },
        moveTo(item, path){
            this.update({information:item})
            this.clearError()
            move(this.$router, path)
        },
        create(){
            this.clearError()
            this.update({information:{
                title:null,
                text:null,
                show_type:null,
                show_users:0,
            }})
            move(this.$router, '/admin/information/create')
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 800px;
}
.lists_table >>> .el_button:not(:last-child){
    margin-right: 8px;
}
</style>