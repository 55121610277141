<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト'"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">お知らせ{{typeString}}</h2>
            </div>
            <error-list />
            <information-form />
            <div class="ly_buttonBox">
                <app-button
                    name="戻る"
                    class="cancel"
                    @click="cancel"
                    ref="jest-informations-back"
                />
                <app-button
                    :name="buttonString"
                    @click="submit"
                    ref="jest-informations-submit"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { move } from '@/util'
import Progress from '@/mixins/progress.js'
import ErrorList from '@/components/molecules/ErrorList'
import InformationForm from '@/components/organisms/InformationForm'

export default {
    name: 'AdminInformationsEdit',
    mixins:[Progress],
    components:{
        ErrorList,
        InformationForm,
    },
    computed:{
        ...mapGetters({
            information:'informations/information'
        }),
        typeString(){
            return this.isCreate ? '作成' : '変更'
        },
        buttonString(){
            return this.isCreate ? '作成' : '変更確定'
        },
        isCreate(){
            return this.$route.meta.type === 'create'
        }
    },
    methods:{
        ...mapActions({
            create:'informations/create',
            edit:'informations/edit',
            clearError:'error/clear',
            updateError:'error/update'
        }),
        moveTo(path){
            move(this.$router, path)
        },
        back(){
            this.moveTo('/admin/information')
        },
        cancel(){
            this.clearError()
            this.back()
        },
        submit(){
            this.clearError()
            return this[this.$route.meta.type]()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 600px;
    max-width: 600px;
}
.ly_userForm{
    width: 100%;
}

.ly_userForm >>> .select_btn{
    min-width: 12em;
}
</style>